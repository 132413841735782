import React from 'react';
import { Row, Col, Form, Select, Input, Tag, Typography } from 'antd';
import {
  ManOutlined,
  WomanOutlined,
  CalendarOutlined,
  UserOutlined,
  EyeOutlined,
  ScissorOutlined,
  DashboardOutlined,
} from '@ant-design/icons';
import BmiIndicator from '../BmiIndicateur';

const { Title } = Typography;

const CandidateDetailsForm = ({ candidate, isEditing, form, bmi }) => {
  const gender = form.getFieldValue('gender');

  return (
    <div style={{ padding: '12px' }}>
      <Form
        form={form}
        initialValues={candidate}
        layout="vertical"
        style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
      >
        <div style={{ textAlign: 'center', marginBottom: '24px' }}>
      <Title level={3} style={{ marginBottom: '2px', color: '#5A6650' }}>
        {candidate.firstName} {candidate.name}
      </Title>
      
    </div>
        {/* Basic Information Section */}
        <div>
          <Title level={4}>Basic Information</Title>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item>
                <span>
                  <strong>Gender:</strong>{' '}
                  {isEditing ? (
                    <Select>
                      <Select.Option value="Homme">Homme</Select.Option>
                      <Select.Option value="Femme">Femme</Select.Option>
                    </Select>
                  ) : (
                    <>
                      {candidate.gender?.toLowerCase() === 'femme' ? (
                        <WomanOutlined style={{ color: '#ff85c0', marginRight: '8px' }} />
                      ) : (
                        <ManOutlined style={{ color: '#69c0ff', marginRight: '8px' }} />
                      )}
                      {candidate.gender}
                    </>
                  )}
                </span>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <span>
                  <strong>Birth Year:</strong>{' '}
                  {isEditing ? (
                    <Input />
                  ) : (
                    <>
                      <CalendarOutlined style={{ color: '#faad14', marginRight: '8px' }} />
                      {candidate.birthYear}
                    </>
                  )}
                </span>
              </Form.Item>
            </Col>
          </Row>
        </div>

        {/* Physical Attributes Section */}
        <div>
          <Title level={4}>Physical Attributes</Title>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item>
                <span>
                  <strong>Height:</strong>{' '}
                  {isEditing ? (
                    <Input />
                  ) : (
                    <>
                      <UserOutlined style={{ color: '#52c41a', marginRight: '8px' }} />
                      {candidate.height} m
                    </>
                  )}
                </span>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <span>
                  <strong>Weight:</strong>{' '}
                  {isEditing ? (
                    <Input />
                  ) : (
                    <>
                      <UserOutlined style={{ color: '#722ed1', marginRight: '8px' }} />
                      {candidate.weight} kg
                    </>
                  )}
                </span>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <span>
                  <strong>Eye Color:</strong>{' '}
                  {isEditing ? (
                    <Select>
                      <Select.Option value="Brown">Brown</Select.Option>
                      <Select.Option value="Blue">Blue</Select.Option>
                    </Select>
                  ) : (
                    <>
                      <EyeOutlined style={{ color: '#13c2c2', marginRight: '8px' }} />
                      {candidate.eyeColor}
                    </>
                  )}
                </span>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <span>
                  <strong>Hair Color:</strong>{' '}
                  {isEditing ? (
                    <Select>
                      <Select.Option value="Black">Black</Select.Option>
                      <Select.Option value="Blonde">Blonde</Select.Option>
                    </Select>
                  ) : (
                    <>
                      <ScissorOutlined style={{ color: '#eb2f96', marginRight: '8px' }} />
                      {candidate.hairColor}
                    </>
                  )}
                </span>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <span>
                  <strong>BMI:</strong>{' '}
                  <DashboardOutlined style={{ color: '#faad14', marginRight: '8px' }} />
                  <BmiIndicator bmi={bmi} display={!isEditing} />
                </span>
              </Form.Item>
            </Col>
          </Row>
        </div>

        {/* Interests Section */}
        <div>
          <Title level={4}>Interests</Title>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item>
                <span>
                  <strong>Interests:</strong>{' '}
                  {isEditing ? (
                    <Select mode="multiple">
                      {candidate.interests.map((interest) => (
                        <Select.Option key={interest} value={interest}>
                          {interest}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                      {candidate.interest?.flatMap((interest) => interest.split(',')).map((interest, index) => {
                // Rotate through three colors
                const colors = [ '#1890ff','#52c41a', '#faad14']; // Blue, Green, Yellow
                const color = colors[index % colors.length];
                return (
                  <Tag key={index} color={color}>
                    {interest}
                  </Tag>
                );
              })}
                    </div>
                  )}
                </span>
              </Form.Item>
            </Col>
          </Row>
        </div>

        {/* Additional Attributes Section */}
        {gender === 'Femme' && (
          <div>
            <Title level={4}>Additional Attributes</Title>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item>
                  <span>
                    <strong>Veiled:</strong>{' '}
                    {isEditing ? (
                      <Input type="checkbox" />
                    ) : (
                      <span>{candidate.veiled ? 'Yes' : 'No'}</span>
                    )}
                  </span>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <span>
                    <strong>Pregnant:</strong>{' '}
                    {isEditing ? (
                      <Input type="checkbox" />
                    ) : (
                      <span>{candidate.pregnant ? 'Yes' : 'No'}</span>
                    )}
                  </span>
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}
      </Form>
    </div>
  );
};

export default CandidateDetailsForm;
